module.exports = function parseHistoryItem(historyLog){
    return historyLog && {
        historyType: historyLog[0],
        currencyA: historyLog[1],
        currencyB: historyLog[2],
        rate: historyLog[3],
        type: historyLog[4],
        amount: historyLog[5],
        fee: historyLog[6],
        buyRate: historyLog[7],
        details: historyLog[8]
    };
}
