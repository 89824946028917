var time = require('./time');

function and(){
    return Array.from(arguments).reduce((result, item) => result && item, true)
}

function or(){
    return Array.from(arguments).reduce((result, item) => result || item, false)
}

function ratio(a, b){
    return 1 / a * b
}

module.exports = {
    ...time,
    and,
    or,
    ratio
};