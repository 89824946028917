var renderPlaces = require('./renderPlaces');
var ruleExplorer = require('./ruleExplorer');


module.exports = function(fastn, state){
    var binding = fastn.binding;

    var tradeState = new fastn.Model();

    function activeRule(rule){
        return binding('showRules', function(active){
            if(active === rule){
                return 'active'
            }
        }).attach(tradeState)
    }

    return fastn('div', { class: 'details tradeDetails', display: binding('.') },
        fastn('nav',
            fastn('button', { class: activeRule('grab') }, 'Grab').on('click', () => tradeState.set('showRules', 'grab')),
            fastn('button', { class: activeRule('buy') }, 'Buy').on('click', () => tradeState.set('showRules', 'buy')),
            fastn('button', { class: activeRule('sell') }, 'Sell').on('click', () => tradeState.set('showRules', 'sell')),
            fastn('button', { class: activeRule('dump') }, 'Dump').on('click', () => tradeState.set('showRules','dump' )),
            fastn('button', 'Close').on('click', () => state.remove('selectedTradeRow'))
        ),
        ruleExplorer(fastn, state, binding('showRules').attach(tradeState), fastn.binding('selectedTradeRow.details.decisionData').attach(state))
    ).binding(fastn.binding('selectedTradeRow'));
};