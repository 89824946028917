var parseHistoryItem = require('./parseHistoryItem');
var timeago = require('timeago.js');
var predator = require('predator');
var ago = require('timeago.js').format;

timeago.register('short', (number, index, total_sec) => {
  return [
    ['0', '0'],
    ['%s secs', '%s secs'],
    ['1 min', '1 min'],
    ['%s mins', '%s mins'],
    ['1 hr', '1 hr'],
    ['%s hrs', '%s hrs'],
    ['1 d', '1 d'],
    ['%s d', '%s d'],
    ['1 w', '1 w'],
    ['%s w', '%s w'],
    ['1 mo', '1 mo'],
    ['%s mo', '%s mo'],
    ['1 y', '1 y'],
    ['%s y', '%s y']
  ][index];
});

module.exports = function(canvas){
    canvas = canvas || document.createElement('canvas');
    var context = canvas.getContext('2d');

    var data,
        events,
        colour,
        renderText,
        millisecondsPerPixel,
        minResolution = 1,
        maxResolution = window.devicePixelRatio,
        resolution = minResolution,
        targetResolution,
        needsUpdate,
        lowRes;

    function updateResolution(){
        lowRes = predator(canvas).hidden;
        requestIdleCallback(updateResolution, { timeout: 500 });
    }
    updateResolution();

    function update(newData, newEvents, newColour, newRenderText, newMillisecondsPerPixel){
        data = newData;
        events = newEvents;
        colour = newColour;
        renderText = newRenderText;
        millisecondsPerPixel = newMillisecondsPerPixel || 10000;
        needsUpdate = true;
    }

    function render(){
        targetResolution = lowRes ? minResolution : maxResolution;
        canvas.imageSmoothingEnabled = !lowRes;

        if(
            !canvas.parentElement ||
            !data ||
            data.length < 1 ||
            (!needsUpdate && resolution === targetResolution)
        ){
            requestIdleCallback(render, { timeout: 500 });
            return;
        }

        if(lowRes){
            resolution = targetResolution;
            requestIdleCallback(render, { timeout: 300 });
            return;
        } else if(resolution < targetResolution) {
            resolution = maxResolution;
        }

        needsUpdate = false;

        var pixelWidth = canvas.parentElement.scrollWidth;
        var pixelHeight = canvas.parentElement.scrollHeight;
        var aspectRatio = 1 / pixelWidth * pixelHeight

        canvas.width = pixelWidth * resolution;
        canvas.height = pixelWidth * resolution * aspectRatio;

        var renderedMillisecondsPerPixel = millisecondsPerPixel / resolution;

        var firstUsedIndex = data.length - 1;
        var endTime = data[data.length - 1][0];
        var width = canvas.width;

        while(firstUsedIndex > 0 && endTime - data[firstUsedIndex][0] < width * renderedMillisecondsPerPixel){
            firstUsedIndex--;
        }

        var renderedData = data.slice(firstUsedIndex);
        context.clearRect(0, 0, canvas.width, canvas.height);
        var min = Infinity;
        var max = -Infinity;
        var current = renderedData[renderedData.length - 1][1];
        var lastTimestamp = renderedData[renderedData.length - 1][0];
        var now = Date.now();

        renderedData.forEach(function(item){
            min = Math.min(min, item[1]);
            max = Math.max(max, item[1]);
        });

        var contentPadding = 3;
        var displayScalar = 0.1;

        function plotX(time){
            return (time - endTime) / renderedMillisecondsPerPixel + canvas.width - contentPadding;
        }
        function plotY(value){
            var renderHeight = canvas.height - contentPadding * 2;
            return renderHeight - renderHeight / (current * displayScalar) * (value - (current * (1 - 1 * displayScalar))) + renderHeight / 2 + contentPadding
        }

        function plotLine(color, items, plotter){
            context.beginPath();
            context.strokeStyle = color;
            context.lineWidth = 2;
            var firstPoint = plotter(items[items.length - 1]);
            var pointBefore = firstPoint;
            var point2Before = firstPoint;
            context.moveTo(firstPoint[0], firstPoint[1]);

            items.reverse().forEach(function(item){
                if(pointBefore[0] < 0){
                    return;
                }
                var point = plotter(item);
                context.lineTo(
                    point[0],
                    point[1]
                );
                point2Before = pointBefore;
                pointBefore = point;
            });
            context.stroke();
            context.closePath();
        }

        function plotDots(dots, plotter){
            context.lineWidth = 0;
            pointBefore = [Infinity];
            context.font = `${16 * resolution}px Arial`;
            context.textAlign = 'right';

            dots.forEach(function(item){
                if(pointBefore[0] < 0){
                    return;
                }
                var event = parseHistoryItem(item[1])
                var point = plotter(item[0], event);
                var colour;
                var up = event.rate > event.buyRate;
                var isActualTrade = event.historyType === 'completeTrade';
                if(isActualTrade){
                    colour = event.type === 'buy' ? 'rgb(50, 100, 255)' : up ? 'green' : 'red'
                } else {
                    colour = event.type === 'buy' ? 'rgba(0, 100, 255, 0.3)' : up ? 'rgba(100, 255, 0, 0.3)' : 'rgba(255, 0, 100, 0.3)'
                }
                context.fillStyle = colour;
                pointBefore = point;
                context.beginPath();
                var size = isActualTrade ? 3 : 1.5
                context.arc(point[0], point[1], size, 0, 2 * Math.PI);
                context.closePath();
                context.fill();
            });

            context.closePath();
        }

        function plotGridY(){
            context.beginPath();
            context.strokeStyle = 'rgba(255,255,255,0.2)';
            context.lineWidth = 1;
            var y = canvas.height - contentPadding;
            var yStep = y / (100 * displayScalar)

            while((y -= yStep) > contentPadding){
                context.moveTo(contentPadding, y);
                context.lineTo(
                    canvas.width - contentPadding,
                    y
                );
            }
            context.stroke();
            context.closePath();
        }

        function plotGridX(timespan){
            var gridXSpacing = timespan / renderedMillisecondsPerPixel;

            function forInterval(timespan, frequency, minumumRenderWidth, fn){
                var timeAgo = 0;
                var i = 0;

                if(timespan / renderedMillisecondsPerPixel < minumumRenderWidth){
                    return
                }

                while(timeAgo < canvas.width * renderedMillisecondsPerPixel){
                    timeAgo += timespan;
                    if(frequency === 1 || i++ % frequency){
                        var currentX = plotX(lastTimestamp - timeAgo);
                        fn(currentX, timeAgo);
                    }
                }
            }

            context.lineWidth = 1;
            context.beginPath();
            context.strokeStyle = 'rgba(255,255,255,0.2)';

            forInterval(timespan, 1, 5, (xPosition) => {
                context.moveTo(xPosition, contentPadding);
                context.lineTo(
                    xPosition,
                    canvas.height - contentPadding
                );
            })

            context.stroke();
            context.closePath();

            forInterval(timespan, 1, 30, (xPosition, time) => {
                context.font = `${9 * resolution}px Arial`;
                context.textAlign = 'left';
                context.fillStyle = 'rgba(255, 255, 255, 0.5)';
                context.fillText(ago(lastTimestamp - time, 'short'), xPosition + contentPadding, canvas.height - contentPadding * 2)
            })
        }

        plotGridY();
        plotGridX(10 * 1000);
        plotGridX(60 * 1000);
        plotGridX(60 * 60 * 1000);
        plotGridX(24 * 60 * 60 * 1000);
        plotGridX(7 * 24 * 60 * 60 * 1000);

        plotLine(colour || 'rgba(0,100,255,0.8)', renderedData, function(item){
            return [plotX(item[0]), plotY(item[1])];
        });

        plotDots(events, function(time, event){
            var price = event.rate * event.amount;

            return [plotX(time), plotY(event.rate), event.rate, price];
        });


        if(renderText){
            context.beginPath();
            context.font = `${12 * resolution}px Arial`;
            context.textAlign = 'left';
            context.fillStyle = 'white';
            context.fillText(max.toFixed(6), contentPadding, contentPadding + 16);
            context.fillText(min.toFixed(6), contentPadding, canvas.height - contentPadding);
            context.closePath();
        }

        setTimeout(render, 50);
    }

    render();

    return {
        canvas: canvas,
        render: render,
        update: update
    };
}
