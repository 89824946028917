var is = require('./is'),
    firmer = require('./firmer'),
    functionEmitter = require('function-emitter'),
    setPrototypeOf = require('setprototypeof'),
    same = require('same-value');

function noop(x){
    return x;
}

function fuseBinding(){
    var fastn = this,
        args = Array.prototype.slice.call(arguments);

    var bindings = args.slice(),
        transform = bindings.pop(),
        updateTransform,
        resultBinding = createBinding.call(fastn),
        selfChanging;

    resultBinding._arguments = args;

    if(typeof bindings[bindings.length-1] === 'function' && !is.binding(bindings[bindings.length-1])){
        updateTransform = transform;
        transform = bindings.pop();
    }

    resultBinding._model.removeAllListeners();
    resultBinding._set = function(value){
        if(updateTransform){
            selfChanging = true;
            var newValue = updateTransform(value);
            if(!same(newValue, bindings[0]())){
                bindings[0](newValue);
                resultBinding._change(newValue);
            }
            selfChanging = false;
        }else{
            resultBinding._change(value);
        }
    };

    function change(){
        if(selfChanging){
            return;
        }
        resultBinding(transform.apply(null, bindings.map(function(binding){
            return binding();
        })));
    }

    resultBinding.on('detach', function(firm){
        bindings.forEach(function(binding, index){
            binding.detach(firm);
        });
    });

    resultBinding.once('destroy', function(soft){
        bindings.forEach(function(binding, index){
            binding.removeListener('change', change);
            binding.destroy(soft);
        });
    });

    bindings.forEach(function(binding, index){
        if(!is.binding(binding)){
            binding = createBinding.call(fastn, binding);
            bindings[index] = binding;
        }
        binding.on('change', change);
    });

    var lastAttached;
    resultBinding.attach = function(object, firm){
        if(firmer(resultBinding, firm)){
            return resultBinding;
        }

        resultBinding._firm = firm;

        selfChanging = true;
        bindings.forEach(function(binding){
            binding.attach(object, 1);
        });
        selfChanging = false;
        if(lastAttached !== object){
            change();
        }
        lastAttached = object;
        resultBinding._model.attach(object);
        resultBinding.emit('attach', object, firm);
        return resultBinding;
    }

    return resultBinding;
}

function createValueBinding(fastn){
    var valueBinding = createBinding.call(fastn, 'value');
    valueBinding.attach = function(){return valueBinding;};
    valueBinding.detach = function(){return valueBinding;};
    return valueBinding;
}

function bindingTemplate(newValue){
    if(!arguments.length){
        return this.value;
    }

    if(this.binding._fastn_binding === '.'){
        return;
    }

    this.binding._set(newValue);
    return this.binding;
}

function modelAttachHandler(data){
    var bindingScope = this;
    bindingScope.binding._model.attach(data);
    bindingScope.binding._change(bindingScope.binding._model.get(bindingScope.path));
    bindingScope.binding.emit('attach', data, 1);
}

function modelDetachHandler(){
    this.binding._model.detach();
}

function attach(object, firm){
    var bindingScope = this;
    var binding = bindingScope.binding;
    // If the binding is being asked to attach loosly to an object,
    // but it has already been defined as being firmly attached, do not attach.
    if(firmer(binding, firm)){
        return binding;
    }

    binding._firm = firm;

    var isModel = bindingScope.fastn.isModel(object);

    if(isModel && bindingScope.attachedModel === object){
        return binding;
    }

    if(bindingScope.attachedModel){
        bindingScope.attachedModel.removeListener('attach', bindingScope.modelAttachHandler);
        bindingScope.attachedModel.removeListener('detach', bindingScope.modelDetachHandler);
        bindingScope.attachedModel = null;
    }

    if(isModel){
        bindingScope.attachedModel = object;
        bindingScope.attachedModel.on('attach', bindingScope.modelAttachHandler);
        bindingScope.attachedModel.on('detach', bindingScope.modelDetachHandler);
        object = object._model;
    }

    if(binding._model._model === object){
        return binding;
    }

    bindingScope.modelAttachHandler(object);

    return binding;
};

function detach(firm){
    if(firmer(this.binding, firm)){
        return this.binding;
    }

    this.value = undefined;
    if(this.binding._model.isAttached()){
        this.binding._model.detach();
    }
    this.binding.emit('detach', 1);
    return this.binding;
}

function set(newValue){
    var bindingScope = this;
    if(same(bindingScope.binding._model.get(bindingScope.path), newValue)){
        return;
    }
    if(!bindingScope.binding._model.isAttached()){
        bindingScope.binding._model.attach(bindingScope.binding._model.get('.'));
    }
    bindingScope.binding._model.set(bindingScope.path, newValue);
}

function change(newValue){
    var bindingScope = this;
    if(newValue === undefined && bindingScope.value === newValue && !bindingScope.binding._model._model){
        return;
    }
    bindingScope.value = newValue;
    bindingScope.binding.emit('change', bindingScope.binding());
}

function clone(keepAttachment){
    var bindingScope = this;
    var newBinding = createBinding.apply(bindingScope.fastn, bindingScope.binding._arguments);

    if(keepAttachment){
        newBinding.attach(bindingScope.attachedModel || bindingScope.binding._model._model, bindingScope.binding._firm);
    }

    return newBinding;
}

function destroy(soft){
    var bindingScope = this;
    if(bindingScope.isDestroyed){
        return;
    }
    if(soft){
        return;
    }
    bindingScope.isDestroyed = true;
    bindingScope.binding.emit('destroy', true);
    bindingScope.binding.detach();
    bindingScope.binding._model.destroy();
}

function destroyed(){
    return this.isDestroyed;
}

function createBinding(path, more){
    var fastn = this;

    if(more){ // used instead of arguments.length for performance
        return fuseBinding.apply(fastn, arguments);
    }

    if(is.binding(path)){
        return createBinding.call(this, path, noop);
    }

    if(arguments.length === 0){
        return createValueBinding(fastn);
    }

    if(!(typeof path === 'string' || typeof path === 'number')){
        throw new Error('Invalid path for fastn.binding(String/Number), saw: ', JSON.stringify(path))
    }

    var bindingScope = {
            fastn: fastn,
            path: path
        },
        binding = bindingScope.binding = bindingTemplate.bind(bindingScope);

    setPrototypeOf(binding, functionEmitter);
    binding.setMaxListeners(10000);
    binding._arguments = [path];
    binding._model = new fastn.Model(false);
    binding._fastn_binding = path;
    binding._firm = -Infinity;

    bindingScope.modelAttachHandler = modelAttachHandler.bind(bindingScope);
    bindingScope.modelDetachHandler = modelDetachHandler.bind(bindingScope);

    binding.attach = attach.bind(bindingScope);
    binding.detach = detach.bind(bindingScope);
    binding._set = set.bind(bindingScope);
    binding._change = change.bind(bindingScope);
    binding.clone = clone.bind(bindingScope);
    binding.destroy = destroy.bind(bindingScope);
    binding.destroyed = destroyed.bind(bindingScope);

    if(path !== '.'){
        binding._model.on(path, binding._change);
    }

    return binding;
}

function from(valueOrBinding){
    if(is.binding(valueOrBinding)){
        return valueOrBinding;
    }

    var result = this();
    result(valueOrBinding)

    return result;
}

module.exports = function(fastn){
    var binding = createBinding.bind(fastn);
    binding.from = from.bind(binding);
    return binding;
};
