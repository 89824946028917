var MultiMap = require('multimap'),
    merge = require('flat-merge');

var requestIdleCallback = global.requestIdleCallback || global.requestAnimationFrame || global.setTimeout;

MultiMap.Map = Map;

function each(value, fn){
    if(!value || typeof value !== 'object'){
        return;
    }

    if(Array.isArray(value)){
        for(var i = 0; i < value.length; i++){
            fn(value[i], i)
        }
    }else{
        for(var key in value){
            fn(value[key], key);
        }
    }
}

function keyFor(object, value){
    if(!object || typeof object !== 'object'){
        return false;
    }

    if(Array.isArray(object)){
        var index = object.indexOf(value);
        return index >=0 ? index : false;
    }

    for(var key in object){
        if(object[key] === value){
            return key;
        }
    }

    return false;
}

module.exports = function(fastn, component, type, settings, children){

    if(fastn.components._generic){
        component.extend('_generic', settings, children);
    }else{
        component.extend('_container', settings, children);
    }

    if(!('template' in settings)){
        console.warn('No "template" function was set for this templater component');
    }

    var itemsMap = new MultiMap(),
        dataMap = new WeakMap(),
        lastTemplate,
        existingItem = {};

    var insertQueue = [];
    var inserting;

    function updateOrCreateChild(template, item, key){
        var child,
            existing;

        if(Array.isArray(item) && item[0] === existingItem){
            existing = true;
            child = item[2];
            item = item[1];
        }

        var childModel;

        if(!existing){
            childModel = new fastn.Model({
                item: item,
                key: key
            });

            child = fastn.toComponent(template(childModel, component.scope()));
            if(!child){
                child = fastn('template');
            }
            child._listItem = item;
            child._templated = true;

            dataMap.set(child, childModel);
            itemsMap.set(item, child);
        }else{
            childModel = dataMap.get(child);
            childModel.set('key', key);
        }

        if(fastn.isComponent(child) && component._settings.attachTemplates !== false){
            child.attach(childModel, 2);
        }

        return child;
    }

    function insertNextItems(template, insertionFrameTime){
        if(inserting){
            return;
        }

        inserting = true;
        component.emit('insertionStart', insertQueue.length);

        insertQueue.sort(function(a, b){
            return a[2] - b[2];
        });

        function insertNext(){
            var startTime = Date.now();

            while(insertQueue.length && Date.now() - startTime < insertionFrameTime) {
                var nextInsersion = insertQueue.shift();
                var child = updateOrCreateChild(template, nextInsersion[0], nextInsersion[1]);
                component.insert(child, nextInsersion[2]);
            }

            if(!insertQueue.length || component.destroyed()){
                inserting = false;
                if(!component.destroyed()){
                    component.emit('insertionComplete');
                }
                return;
            }

            requestIdleCallback(insertNext);
        }

        insertNext();
    }

    function updateItems(){
        insertQueue = [];

        var value = component.items(),
            template = component.template(),
            emptyTemplate = component.emptyTemplate(),
            insertionFrameTime = component.insertionFrameTime() || Infinity,
            newTemplate = lastTemplate !== template;

        var currentItems = merge(template ? value : []);

        itemsMap.forEach(function(childComponent, item){
            var currentKey = keyFor(currentItems, item);

            if(!newTemplate && currentKey !== false){
                currentItems[currentKey] = [existingItem, item, childComponent];
            }else{
                removeComponent(childComponent);
                itemsMap.delete(item, childComponent);
            }
        });

        var index = 0;
        var templateIndex = 0;

        function updateItem(item, key){
            while(index < component._children.length && !component._children[index]._templated){
                index++;
            }

            insertQueue.push([item, key, index + templateIndex]);
            templateIndex++;
        }

        each(currentItems, updateItem);

        template && insertNextItems(template, insertionFrameTime);

        lastTemplate = template;

        if(templateIndex === 0 && emptyTemplate){
            var child = fastn.toComponent(emptyTemplate(component.scope()));
            if(!child){
                child = fastn('template');
            }
            child._templated = true;

            itemsMap.set({}, child);

            component.insert(child);
        }
    }

    function removeComponent(childComponent){
        component.remove(childComponent);
        childComponent.destroy();
    }

    component.setProperty('insertionFrameTime');

    component.setProperty('items',
        fastn.property([], settings.itemChanges || 'type keys shallowStructure')
            .on('change', updateItems)
    );

    component.setProperty('template',
        fastn.property().on('change', updateItems)
    );

    component.setProperty('emptyTemplate',
        fastn.property().on('change', updateItems)
    );

    return component;
};