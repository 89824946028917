
function seconds(value){
    return value * 1000
}

function minutes(value){
    return seconds(value) * 60
}

function hours(value){
    return minutes(value) * 60
}

function days(value){
    return hours(value) * 24
}

function weeks(value){
    return days(value) * 7
}

module.exports = {
    seconds,
    minutes,
    hours,
    days,
    weeks
};