module.exports = function(fastn){
    return function(updateFrequency){
        var args = Array.prototype.slice.call(arguments, 1);
        var intervalBinding = fastn.binding();
        var resultBinding = fastn.binding.apply(fastn, [intervalBinding].concat(args));
        var isDetached;

        function queueUpdate(){
            if(isDetached){
                return;
            }

            intervalBinding(Date.now());

            setTimeout(queueUpdate, updateFrequency);
        }

        resultBinding.on('attach', queueUpdate);
        resultBinding.on('detach', () => {
            isDetached = true;
        });

        return resultBinding;
    }
}