var clientVersion = '1.0.1';
var clientName = `stateHistory:${clientVersion}`;
var API_URL = 'https://trader-api.korynunn.com';

var righto = require('righto');
var cpjax = require('cpjax');
var fastn = require('fastn')(require('fastn/domComponents')({
    graph: require('./graphComponent'),
    preshExplorer: require('presh-explorer/preshExplorerComponent')
}));
var binding = fastn.binding;
var intervalBinding = require('./intervalBinding')(fastn);
var time = require('./time');
var parseHistoryItem = require('./parseHistoryItem');
var marketDetailsUi = require('./marketDetails');
var tradeDetailsUi = require('./tradeDetails');
var renderPlaces = require('./renderPlaces');
var ago = require('timeago.js').format;
var debounce = require('debounce');

var ui;

function formatDate(date){
    return new Date(date).toLocaleString();
}

function stringify(data){
    return JSON.stringify(data, null, 4);
}

function toMarketCode(keyA, keyB){
    if(keyA == null || keyB == null){
        return
    }
    return `${keyA}_${keyB}`;
}

var state = new fastn.Model({
    totals: [],
    history: [],
    trendCache: {},
    sortColumn: 'active',
    filterOwned: true,
    lastTrendUpdate: 0,
    graphZoomX: 10000
});

function loadRules(callback){
    var rules = righto(cpjax, {
        method: 'GET',
        url: `${API_URL}/rules`,
        dataType: 'json'
    });

    rules(callback);
}

function saveRules(newRules){
    var data = {
        buyAmount: newRules.buyAmountRule,
        sellAmount: newRules.sellAmountRule,
        buy: newRules.buyRules,
        sell: newRules.sellRules,
        dump: newRules.dumpRules,
        grab: newRules.grabRules
    };

    var saved = righto.from(fetch(`${API_URL}/rules`, {
        method: 'POST',
        headers: {
            'x-trader-secret': window.localStorage.getItem('trader-secret'),
            'Content-Type': 'application/json'
        },
        url: `${API_URL}/rules`,
        body: JSON.stringify(data)
    }));

    saved(console.log);
}

function updateLastActiveHistory(event){
    var now = Date.now();
    var lastActive = state.get('lastActive');
    if(now - lastActive > 10000){
        state.set('previousActive', lastActive);
    }

    state.set('lastActive', now);
}

try {
    var loadedState = JSON.parse(window.localStorage.getItem(clientName));
    delete loadedState.selectedRow;
    delete loadedState.selectedTradeRow;
    state.update(loadedState);
} catch(error){}

function getProfit(item){
    if(!item.lastBuyRate || item.balance < 0.0001){
        return;
    }

    return 100 / item.lastBuyRate * (item.highestBid - item.lastBuyRate);
}

var sortMethods = {
    active: function(itemA){
        var a = (
            (itemA.shouldBuyResults ? 1000000 : 0) +
            ((itemA.balance || 0) + (itemA.marketOnSellOrder || 0) > 0.0001 && itemA.shouldDumpResults ? 1000000 : 0) +
            (itemA.shouldGrabResults ? 1000000 : 0) +
            ((itemA.balance || 0) + (itemA.marketOnSellOrder || 0) > 0.0001 && itemA.shouldSellResults ? 1000000 : 0) +
            parseFloat(itemA.balance || 0) + (itemA.marketOnSellOrder || 0) + (itemA.marketOnBuyOrder || 0)
        )
        var b = (
            (itemA.shouldBuyResults ? 1000000 : 0) +
            ((itemA.balance || 0) + (itemA.marketOnSellOrder || 0) > 0.0001 && itemA.shouldDumpResults ? 1000000 : 0) +
            (itemA.shouldGrabResults ? 1000000 : 0) +
            ((itemA.balance || 0) + (itemA.marketOnSellOrder || 0) > 0.0001 && itemA.shouldSellResults ? 1000000 : 0) +
            parseFloat(itemA.balance || 0) + (itemA.marketOnSellOrder || 0) + (itemA.marketOnBuyOrder || 0)
        )

        return a - b;
    },
    profit: function(itemA, itemB){
        var profitA = getProfit(itemA);
        var profitB = getProfit(itemB);

        return profitA - profitB;
    },
    difference: function(itemA, itemB){
        return itemA.difference - itemB.difference;
    },
    balance: function(itemA, itemB){
        return (
            parseFloat(itemA.balance || 0) + (itemA.marketOnSellOrder || 0) + (itemA.marketOnBuyOrder || 0) - 
            parseFloat(itemB.balance || 0) + (itemB.marketOnSellOrder || 0) + (itemB.marketOnBuyOrder || 0)
        );
    },
    marketKey: function(itemA, itemB){
        return itemA.marketKey > itemB.marketKey ? 1 : -1;
    }
};

var marketInfoItems = binding('marketInfos|*.*', 'sortColumn', 'sortDirection', (infos, sortColumn, sortDirection) => infos && Object.keys(infos).map(key =>
    infos[key]).sort(function(a, b){
        if(sortDirection){
            var x = a;
            a = b;
            b = x;
        }

        return sortMethods[sortColumn] ?
            sortMethods[sortColumn](a, b) :
            b[sortColumn] - a[sortColumn]
    })
).attach(state);
var selectedIndex = binding(marketInfoItems, 'selectedRow', function(marketInfoItems, selectedRow){
    var index = marketInfoItems && marketInfoItems.indexOf(selectedRow);
    return index >= 0 ? index : null;
}).attach(state);

function isIn(target, selector){
    return Array.prototype.some.call(document.querySelectorAll(selector), x => x.contains(target));
}

function renderRate(bindingKey){
    var rateParts = binding(bindingKey, function(rate) {
        var parts = renderPlaces(8)(rate).match(/(0\.00+)(.*)|(.*)/);

        return parts ? parts[3] ? [null].concat(parts.slice(3,4)) : parts.slice(1,3) : [];
    });
    return fastn('span', { class: 'rateParts' },
        fastn('span', { class: 'minor' }, binding(rateParts, parts => parts && parts[0])),
        fastn('span', { class: 'major' }, binding(rateParts, parts => parts && parts[1] && parts[1].slice(0, 3))),
        fastn('span', { class: 'minor' }, binding(rateParts, parts => parts && parts[1] && parts[1].slice(3)))
    );
}

function toggleViewMode(){
    state.set('filterOwned', !state.get('filterOwned'));
}

function setSort(column){
    var currentSortColumn = state.get('sortColumn');

    if(currentSortColumn === column){
        state.set('sortDirection', !state.get('sortDirection'));
        return;
    }

    state.set('sortColumn', column);
}

function headerClassBinding(columnName){
    return binding('sortColumn', 'sortDirection', function(sortColumn, sortDirection){
        return [
            sortColumn === columnName ? sortDirection ? 'up' : 'down' : null,
            columnName
        ];
    }).attach(state);
}

var ui = fastn('div', { class: 'app' },
    fastn('section', { class: 'info' },
        fastn('table',
            fastn('thead',
                fastn('tr',
                    fastn('td', { class: headerClassBinding('marketKey') },
                        intervalBinding(100, 'lastUpdate', (time, currentTime) =>
                            ((time - currentTime) / 1000).toFixed(2)
                        ).attach(state),
                        's'
                    ).on('click', setSort.bind(null, 'marketKey')),
                    fastn('td', { class: headerClassBinding('history active') }, 'History').on('click', setSort.bind(null, 'active')),
                    fastn('td', { class: headerClassBinding('lastBuyRate') }, 'Last Buy'),
                    fastn('td', { class: headerClassBinding('highestBid') }, 'Highest Bid').on('click', setSort.bind(null, 'highestBid')),
                    fastn('td', { class: headerClassBinding('profit') }, '% up').on('click', setSort.bind(null, 'profit')),
                    fastn('td', { class: headerClassBinding('balance') }, 'Balance').on('click', setSort.bind(null, 'balance'))
                )
            ),
            fastn('tbody:list', {
                class: binding('selectedRow', (selected) => selected && 'hasSelection'),
                items: marketInfoItems,
                template: function(model){
                    var shown = binding('selectedRow',
                        selectedRow => selectedRow === model.get('item')
                    ).attach(state);

                    var distanceToSelected = binding('key', selectedIndex, function(index, selectedIndex){
                        return selectedIndex !== null ? index - selectedIndex : null;
                    })
                    .attach(model);

                    return fastn('tr', {
                            display: binding(binding('filterOwned').attach(state), 'balance', (filter, balance) =>
                                filter ? balance > 1e-5 : true
                            ),
                            selectionDistance: distanceToSelected,
                            class: binding(shown, 'volume', 'trading', 'direction', 'lastBuyRate', 'highestBid', 'balance', (shown, volume, trading, direction, lastBuyRate, highestBid, balance) => [
                                balance > 0.0001 && 'invested',
                                shown && 'selected',
                                volume <= 50 && 'lowVolume',
                                direction,
                                highestBid < lastBuyRate ? 'loss' : lastBuyRate > highestBid * 1.005 ? 'profit' : '',
                                !trading && 'notTrading'
                            ])
                        },
                        fastn('td', { class: 'marketKey' },
                            fastn('a',
                                {
                                    target: ':blank',
                                    href: binding('marketKey',
                                        binding('baseCurrency').attach(state),
                                        (key, base) => `https://poloniex.com/exchange/${base}_${key}`
                                    )
                                },
                                binding('marketKey')
                            )
                        ),
                        fastn('td', { class: 'history' },
                            fastn('templater', {
                                data: binding(binding('baseCurrency').attach(state), 'marketKey', toMarketCode),
                                attachTemplates: false,
                                template: (model) => {
                                    var marketCode = model.get('item');

                                    if(!marketCode){
                                        return;
                                    }

                                    return fastn('div', { class: 'graphBox' },
                                        fastn('graph', {
                                            data: fastn.binding(`trendCache.${marketCode}.rate|*`, function(x){
                                                return x;
                                            }).attach(state),
                                            events: binding(binding('history|*').attach(state), (historyItems) => {
                                                return historyItems.filter((historyItem) => {
                                                    var history = parseHistoryItem(historyItem[1]);
                                                    return (
                                                        (historyItem[0] > (Date.now() - time.hours(24))) &&
                                                        (history.historyType === 'completeTrade' || history.historyType === 'attemptTrade') &&
                                                        toMarketCode(history.currencyA, history.currencyB) === marketCode
                                                    );
                                                });
                                            }),
                                            color: 'rgb(240, 200, 20)',
                                            zoomX: fastn.binding('graphZoomX').attach(state)
                                        })
                                    )
                                }
                            })
                        ),
                        fastn('td', { class: 'lastBuyRate' }, renderRate('lastBuyRate')),
                        fastn('td', { class: 'highestBid' }, renderRate('highestBid')),
                        fastn('td', { class: 'profit' }, binding(binding('.|*.*', getProfit), profit => profit ? (renderPlaces(2)(profit) + '%') : '\u2022')),
                        fastn('td', { class: 'balance' },
                            fastn('div', binding('balance', balance => balance > 0.0001 ? renderPlaces(4)(balance) :  '\u2022')),
                            fastn('div', { class: 'onBuy', display: binding('marketOnBuyOrder') }, '+', binding('marketOnBuyOrder', marketOnBuyOrder => marketOnBuyOrder > 0.0001 ? renderPlaces(4)(marketOnBuyOrder) : '')),
                            fastn('div', { class: 'onSale', display: binding('marketOnSellOrder') }, '-', binding('marketOnSellOrder', marketOnSellOrder => marketOnSellOrder > 0.0001 ? renderPlaces(4)(marketOnSellOrder) : ''))
                        )
                    )
                    .on('click', (event) => !isIn(event.target, 'button, a') && state.set('selectedRow', shown() ? null : model.get('item')))
                    .binding(fastn.binding('item', item => item || null));
                }
            })
        )
    ),
    fastn('section', { class: 'history', },
        fastn('table:list', {
            class: 'history',
            items: binding('history|*', items =>
                items && items.filter(item => item[1][0] === 'completeTrade')
                .slice(-50)
                .filter(x => x && x[1])
            ),
            template: model => {
                var item = model.get('item');
                var historyItem = { timestamp: item[0], ...parseHistoryItem(item[1]) }

                var buySellAmountBinding = binding('.', function(history){
                    if(!history || history.type === 'buy'){
                        return;
                    }

                    var fee = history.details && history.details.order && history.details.order.fee || 0
                    var sellAmount = (history.amount - fee) * history.rate;
                    var buyAmount = history.amount * history.buyRate;
                    return { buyAmount, sellAmount };
                }).attach(historyItem);

                return fastn('tr', {
                    class: fastn.binding(
                        'item.timestamp',
                        buySellAmountBinding,
                        binding('previousActive').attach(state),
                        (timestamp, buySellAmount, previousActive) => {
                            var buySellAmount = buySellAmount;
                            var up = buySellAmount && buySellAmount.sellAmount > buySellAmount.buyAmount;

                            return [
                                buySellAmount && up && 'up',
                                buySellAmount && !up && 'down',
                                (timestamp + 5 * 60 * 1000) - previousActive > 0 && 'new'
                            ]
                        }
                    )
                },
                    fastn('td', binding('type')),
                    fastn('td', intervalBinding(1000, 'timestamp', (t, timestamp) => ago(timestamp))),
                    fastn('td', binding('currencyB')),
                    fastn('td', binding('type', 'amount', 'rate', 'buyRate', (type, amount, currentRate, buyRate) => renderPlaces(5)(amount * (type === 'sell' ? buyRate : currentRate)))),
                    fastn('td', binding('fee')),
                    fastn('td', renderRate('rate')),
                    fastn('td', binding(binding('baseRate').attach(state), buySellAmountBinding, function(baseRate, buySellAmount){
                        if(!buySellAmount){
                            return;
                        }

                        var buyAmount = buySellAmount.buyAmount;
                        var sellAmount = buySellAmount.sellAmount;

                        return renderPlaces(5)(sellAmount - buyAmount) +
                            ' / $' + renderPlaces(2)((sellAmount - buyAmount) * baseRate) +
                            ' / %' + renderPlaces(2)(100 / buyAmount * sellAmount - 100);
                    }))
                )
                .attach(historyItem)
                .on('click', (event, model) => 
                    !isIn(event.target, 'button') && state.set('selectedTradeRow', model.get('.'))
                )
            }
        },
            fastn('thead',
                fastn('td', 'Type'),
                fastn('td', 'Time'),
                fastn('td', 'Currency'),
                fastn('td', 'Amount'),
                fastn('td', 'Fee'),
                fastn('td', 'Rate'),
                fastn('td', 'Profit/loss Base/USDT/%')
            )
        )
        .on('mousemove touchstart', updateLastActiveHistory)
    ),
    fastn('div', { class: 'graph' },
        fastn('graph', {
            data: fastn.binding('totals', totals => totals.map(item => [item[0], item[1][1]])).attach(state),
            // events: binding(binding('history|*', 'totals.0').attach(state), (historyItems, lastTotal) => {
            //     return historyItems.filter((historyItem) => {
            //         var history = parseHistoryItem(historyItem[1]);
            //         return (
            //             (historyItem[0] > (Date.now() - time.hours(24))) &&
            //             (history.historyType === 'completeTrade' || history.historyType === 'attemptTrade')
            //         );
            //     })
            //     .map(historyItem => {
            //         return [historyItem[0], lastTotal]
            //     });
            // }),
            color: 'rgb(240, 200, 20)',
            showMinMax: true,
            zoomX: fastn.binding('graphZoomX').attach(state)
        })
    ),
    fastn('footer',
        fastn('div', { class: binding('marketAverage', average => average > 1 ? 'up' : 'down' ) },
            'Median: ', binding('marketAverage', renderPlaces(4))
        ),
        fastn('div', 'Total: ',
            binding('total', renderPlaces(5))
        ),
        fastn('div', 'Base: ',
            binding('baseCurrency', 'balances|*', (base, balances) => balances && base && renderPlaces(5)(balances[base]) )
        ),
        fastn('div', '$US: ',
            binding('total', 'baseRate', (total, baseRate) => renderPlaces(2)(total * baseRate))
        ),
        fastn('button', binding('filterOwned', filter => filter ? 'Show All' : 'Show Owned').attach(state)).on('click', toggleViewMode)
    ),
    marketDetailsUi(fastn, state, saveRules),
    tradeDetailsUi(fastn, state)
)
.attach(state)
.render();

var lastHistoryTime = Date.now() - (24 * 60 * 60 * 1000);
var firstFetch = true;

function updateSelectedRow(selectedRow){
    if(!selectedRow){
        return;
    }

    var selectedRowDetails = righto(cpjax, {
        url: `${API_URL}/details/${selectedRow.marketKey}`,
        dataType: 'json'
    })

    selectedRowDetails.get(decisionDetails => {
        if(!decisionDetails){
            return;
        }
        var marketInfos = state.get('marketInfos');
        Object.keys(marketInfos).find(marketKey => {
            if(decisionDetails && decisionDetails.marketKey === marketKey){
                fastn.Model.set(marketInfos[marketKey], 'details', decisionDetails);
                return true;
            }
        })
    })();
}

state.on('selectedRow', updateSelectedRow);

function getMarketHistory(marketCode, type, startTime, endTime, callback){

    var history = righto(cpjax, {
        url: `${API_URL}/details/${marketCode}/history/${type}/${[startTime, endTime].join('-')}`,
        dataType: 'json'
    });

    history(callback)
}

function dedupeEvents(events, maxLength){
    return events
    .sort((a, b) => a[0] - b[0])
    .reduce((results, event) => {
        if(!results.length) {
            results.push(event);
            return results;
        }

        var previousItem = results[results.length - 1];

        if(
            event[0] === previousItem[0]
        ){
            return results
        }

        results.push(event);
        return results;
    }, [])
    .slice(-maxLength)
}

var trendHistoryEvents = [];
var emitTrendHistory = debounce(() => {
    while(trendHistoryEvents.length){
        var event = trendHistoryEvents.shift();
        fastn.Model.emit(...event);
    }
})

function setTrendHistory(target, key, value) {
    target[key] = value;
    trendHistoryEvents.push([target, key, value]);
    emitTrendHistory();
}

function updateTrendHistory(marketCode, type, data){
    var trendCache = state.get('trendCache');
    var latestTimestamp = 0;

    if(!(marketCode in trendCache)){
        trendCache[marketCode] = {};
    }

    if(!(type in trendCache[marketCode])){
        trendCache[marketCode][type] = [];
    }

    var existingTrendHistory = trendCache[marketCode][type];
    existingTrendHistory.push.apply(existingTrendHistory, data);
    var newData = dedupeEvents(existingTrendHistory, 1000);

    setTrendHistory(trendCache[marketCode], type, newData);

    if(newData[newData.length - 1][0] > state.get('lastTrendUpdate')){
        state.set('lastTrendUpdate', newData[newData.length - 1][0]);
    }
}

function getInitialTrendHistory(startTime, callback){

    getMarketHistory('all', 'rate', startTime, Date.now(), function(error, results){
        if(error){
            console.error(error);
            setTimeout(() => getInitialTrendHistory(startTime, callback), 3000);
            return;
        }

        Object.keys(results).forEach(marketCode => {
            updateTrendHistory(marketCode, 'rate', results[marketCode])
        })


        callback();
    });
}

var firstLoad = true;

function getData(){
    var localHistory = state.get('history');
    var lastHistory = localHistory[localHistory.length - 1];
    var lastTrendUpdate = state.get('lastTrendUpdate') || 0;
    var lastHistoryTimestamp = Math.max(lastHistory && lastHistory[0] || lastTrendUpdate, Date.now() - 1000 * 60 * 60 * 24);
    var now = Date.now();

    if(firstLoad || Date.now() - Math.max(lastTrendUpdate, lastHistoryTimestamp) > time.seconds(30)){
        firstLoad = false;
        getInitialTrendHistory(lastHistoryTimestamp, getData);
        return
    }

    var selectedRow = state.get('selectedRow');
    updateSelectedRow(selectedRow);
    var historicData = righto(cpjax, {
        url: `${API_URL}/history/${lastHistoryTime}/!rate`,
        dataType: 'json'
    });

    var info = righto(cpjax, {
        url: `${API_URL}/info`,
        dataType: 'json'
    });

    var rules = righto(loadRules);

    var decisionDetails;

    var updated = righto.mate(info, historicData, decisionDetails, rules);

    updated(function(error, info, history, decisionDetails, rules){
        var now = Date.now();
        setTimeout(getData, 2500);

        if(error){
            return;
        }

        var totals = state.get('totals').slice();

        var tradeHistory = [];
        history.forEach(function(item){
            if(item[1][0] === 'total'){
                totals.push(item);
                while(totals.length > 3000){
                    totals.shift();
                }
                lastHistoryTime = Math.max(lastHistoryTime, item[0]);
            }else if(item[1][0] === 'completeTrade' || item[1][0] === 'attemptTrade'){
                if(item[0] > now - time.days(1)){
                    tradeHistory.push(item)
                }
            }
        });

        state.update('history', dedupeEvents(state.get('history').concat(tradeHistory), 500), { strategy: 'morph' });

        if(firstFetch){
            firstFetch = false;
            totals.sort((a, b) => a[0]-b[0]);
        }

        state.update('totals', totals, { strategy: 'morph' });

        var lastState = state.get('.');
        info.marketInfos = info.marketInfos.reduce(function(result, marketInfo){
            var lastInfo = lastState.marketInfos && lastState.marketInfos[marketInfo.marketKey];

            result[marketInfo.marketKey] = marketInfo;

            updateTrendHistory(toMarketCode(info.baseCurrency, marketInfo.marketKey), 'rate', [[now, marketInfo.highestBid]])

            return result;
        }, {});

        for(var key in info){
            if(!info[key] || typeof info[key] !== 'object') {
                state.set(key, info[key]);
            } else {
                state.update(key, info[key]);
            }
        }
        state.set('lastUpdate', now);

        state.update({
            ...state.get('.'),
            buyAmountRule: rules.buyAmount,
            sellAmountRule: rules.sellAmount,
            buyRules: rules.buy,
            sellRules: rules.sell,
            dumpRules: rules.dump,
            grabRules: rules.grab
        }, { strategy: 'morph' });

        window.localStorage.setItem(clientName, JSON.stringify(state.get('.')));
    });
}

getData()

window.addEventListener('load', function(){
    document.body.appendChild(ui.element);
});
