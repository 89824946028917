var createGraph = require('./graph');
var Hammer = require('hammerjs');

function graphComponent(fastn, component, type, settings, children){
    var graph;

    component.extend('canvas', settings, children);

    function renderGraph(){
        graph && graph.update(
            component.data(),
            component.events(),
            component.color(),
            component.showMinMax(),
            component.zoomX()
        );
    }

    component.setProperty('data',
        fastn.property([], 'type keys shallowStructure')
            .on('change', renderGraph)
    );
    component.setProperty('events',
        fastn.property([], 'type keys shallowStructure')
            .on('change', renderGraph)
    );
    component.setProperty('color',
        fastn.property('', 'value')
            .on('change', renderGraph)
    );
    component.setProperty('showMinMax',
        fastn.property(false, 'value')
            .on('change', renderGraph)
    );
    component.setProperty('zoomX',
        fastn.property(10000, 'value')
            .on('change', renderGraph)
    );

    component.on('render', function(){
        graph = createGraph(component.element);

        var initialZoom;
        var mc = new Hammer.Manager(component.element, {
            recognizers: [
                [Hammer.Pinch, { enable: true }],
            ]
        })
        .on("pinchstart", () => {
            initialZoom = component.zoomX()
        })
        .on("pinch", function(event) {
            component.zoomX(initialZoom / event.scale)
        });
        
        component.element.addEventListener('mousewheel', event => {
            event.preventDefault();
            component.zoomX(component.zoomX() * (2 / Math.PI * (Math.PI / 2 + Math.atan((Math.atan(event.deltaY)) / 50))));
        })
    });

    return component
}

module.exports = graphComponent;
