var renderPlaces = require('./renderPlaces');
var ruleExplorer = require('./ruleExplorer');

module.exports = function(fastn, state, saveRules){
    var binding = fastn.binding;

    function activeRule(rule){
        return binding('showRules', function(active){
            if(active === rule){
                return 'active'
            }
        }).attach(state)
    }

    return fastn('div', { class: 'details', display: binding('selectedRow') },
        fastn('nav',
            fastn('button', { class: activeRule('buyAmountRule') }, 'Buy Amount').on('click', (event, scope) => state.set('showRules', 'buyAmountRule')),
            fastn('button', { class: activeRule('sellAmountRule') }, 'Sell Amount').on('click', (event, scope) => state.set('showRules', 'sellAmountRule')),
            fastn('button', { class: activeRule('grabRules') }, 'Grab').on('click', (event, scope) => state.set('showRules', 'grabRules')),
            fastn('button', { class: activeRule('buyRules') }, 'Buy').on('click', (event, scope) => state.set('showRules', 'buyRules')),
            fastn('button', { class: activeRule('sellRules') }, 'Sell').on('click', (event, scope) => state.set('showRules', 'sellRules')),
            fastn('button', { class: activeRule('dumpRules') }, 'Dump').on('click', (event, scope) => state.set('showRules','dumpRules')),
            fastn('button', 'Close').on('click', () => state.remove('selectedRow'))
        ),
        fastn('section',
            fastn('h1', binding('baseCurrency').attach(state), '_', binding('selectedRow.marketKey')),
            fastn('h3', 'Balance ', binding('selectedRow.balance', renderPlaces(4)), binding('baseCurrency').attach(state))
        ),
        fastn('div', 
            fastn('preshExplorer', {
                source: binding('selectedRow.details.availableDecisionData', data => data && data.join('\n')).attach(state),
                globals: binding('selectedRow.details.decisionData').attach(state),
                resultTransform: (result, token) => {
                    return typeof result === 'number' ? result.toFixed(8).replace(/\.0{8}|0*$/, '') : result
                },
                nodeAction: (event, component, scope, token) => {
                    if(token.type === 'number'){
                        return;
                    }

                    event.stopPropagation();
                    var active = component.element.classList.contains('active')
                    if(active){
                        component.element.classList.remove('active')
                    } else {
                        component.element.classList.add('active')
                    }
                }
            })
        ),
        fastn('div', {
                class: 'rules',
                display: binding('selectedRow')
            },
            ruleExplorer(
                fastn,
                state,
                binding('showRules').attach(state),
                binding('selectedRow.details.decisionData').attach(state),
                saveRules
            )
        )
    );
}