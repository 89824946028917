var decisionFunctions = require('./decisionFunctions');

function inflateFunctions(decisionData){
    if(!decisionData){
        return decisionData;
    }

    var cachedResultFunctions = Object.keys(decisionData.functionResults || {}).reduce((results, key) => {
        results[key] = function(){
            return decisionData.functionResults[key][Array.from(arguments).filter(item => item != null).join(':')]
        }
        return results;
    }, {});

    return {
        ...decisionData,
        ...decisionFunctions,
        ...cachedResultFunctions
    }
}

module.exports = function(fastn, state, showRulesBinding, decisionDataBinding, saveRules){
    var binding = fastn.binding;
    var edit = fastn.binding();
    var displayRules = {
        buyAmountRule: state.get('buyAmountRule'),
        sellAmountRule: state.get('sellAmountRule'),
        buyRules: state.get('buyRules'),
        sellRules: state.get('sellRules'),
        dumpRules: state.get('dumpRules'),
        grabRules: state.get('grabRules')
    };
    var newRules = {
        ...displayRules
    };

    function setRuleSource(target, newSource) {
        var ruleType = showRulesBinding();
        var modelPath = `${ruleType}`;

        fastn.Model.set(target, modelPath, newSource);
    }

    return fastn('templater', {
        data: showRulesBinding,
        attachTemplates: false,
        latestRules: fastn.binding(
            edit,
            'buyAmountRule',
            'sellAmountRule',
            'buyRules',
            'sellRules',
            'dumpRules',
            'grabRules',
            (edit, buyAmountRule, buyRules, sellRules, dumpRules, grabRules) => {
                if(!edit) {
                    fastn.Model.update(displayRules, {
                        buyAmountRule: state.get('buyAmountRule'),
                        sellAmountRule: state.get('sellAmountRule'),
                        buyRules: state.get('buyRules'),
                        sellRules: state.get('sellRules'),
                        dumpRules: state.get('dumpRules'),
                        grabRules: state.get('grabRules')
                    });
                }
            }
        ).attach(state),
        template: (model) => {
            var rulesToShow = model.get('item') || 'grab';

            return fastn('div',
                rulesToShow + 'rules: ',
                ' ',
                fastn('button', binding(edit, edit => edit ? 'Cancel' : 'Edit'))
                .on('click', () => {
                    edit(!edit());
                }),
                ' ',
                fastn('button', { disabled: binding(edit, edit => !edit) }, 'Save')
                .on('click', () => {
                    saveRules(newRules);
                    fastn.Model.update(displayRules, newRules);
                    edit(false);
                }),
                ' ',
                fastn('button', 'Edit secret')
                .on('click', () => {
                    var secret = window.prompt('secret');
                    if(secret){
                        window.localStorage.setItem('trader-secret', secret);
                    }
                }),
                fastn('preshExplorer', {
                    edit,
                    source: binding(rulesToShow).attach(displayRules),
                    globals: binding(decisionDataBinding, inflateFunctions),
                    resultTransform: (result, token) => {
                        return typeof result === 'number' ? result.toFixed(8).replace(/\.0{8}|0*$/, '') : result
                    },
                    nodeAction: (event, component, scope, token) => {
                        if(token.type === 'number'){
                            return;
                        }

                        event.stopPropagation();
                        var active = component.element.classList.contains('active')
                        if(active){
                            component.element.classList.remove('active')
                        } else {
                            component.element.classList.add('active')
                        }
                    }
                })
                .on('source', newSource => {
                    setRuleSource(newRules, newSource)
                })
            )
        }
    });
};