var WhatChanged = require('what-changed'),
    same = require('same-value'),
    firmer = require('./firmer'),
    functionEmitter = require('function-emitter'),
    setPrototypeOf = require('setprototypeof');

var propertyProto = Object.create(functionEmitter);

propertyProto._fastn_property = true;
propertyProto._firm = 1;

function propertyTemplate(value){
    if(!arguments.length){
        return this.observable && typeof this.observable === 'function' && this.observable() || this.property._value;
    }

    if(!this.destroyed){
        if(this.observable){
            typeof this.observable === 'function' && this.observable(value);
            return this.property;
        }

        this.valueUpdate(value);
    }

    return this.property;
}

function changeChecker(current, changes){
    if(changes){
        var changes = new WhatChanged(current, changes);

        return function(value){
            return changes.update(value).any;
        };
    }else{
        var lastValue = current;
        return function(newValue){
            if(!same(lastValue, newValue)){
                lastValue = newValue;
                return true;
            }
        };
    }
}

function propertyBinding(newBinding){
    if(!arguments.length){
        return this.observable;
    }

    if(typeof newBinding === 'string' || typeof newBinding === 'number'){
        newBinding = this.fastn.binding(newBinding);
    }

    if(newBinding === this.observable){
        return this.property;
    }

    if(this.observable){
        this.observable.removeListener('change', this.valueUpdate);
    }

    this.observable = newBinding;

    if(this.model){
        this.property.attach(this.model, this.property._firm);
    }

    this.observable.on('change', this.valueUpdate);
    if(typeof this.observable === 'function'){
        this.valueUpdate(this.observable());
    } else {
        this.valueUpdate(undefined);
    }

    return this.property;
}

function attachProperty(object, firm){
    if(firmer(this.property, firm)){
        return this.property;
    }

    this.property._firm = firm;

    if(!(object instanceof Object)){
        object = {};
    }

    if(this.observable){
        this.model = object;
        this.observable.attach && this.observable.attach(object, 1);
    }

    if(this.property._events && 'attach' in this.property._events){
        this.property.emit('attach', object, 1);
    }

    return this.property;
};

function detachProperty(firm){
    if(firmer(this.property, firm)){
        return this.property;
    }

    if(this.observable){
        this.observable.removeListener('change', this.valueUpdate);
        this.observable.detach && this.observable.detach(1);
        this.model = null;
    }

    if(this.property._events && 'detach' in this.property._events){
        this.property.emit('detach', 1);
    }

    return this.property;
};

function updateProperty(){
    if(!this.destroyed){

        if(this.property._update){
            this.property._update(this.property._value, this.property);
        }

        this.property.emit('update', this.property._value);
    }
    return this.property;
};

function propertyUpdater(fn){
    if(!arguments.length){
        return this.property._update;
    }
    this.property._update = fn;
    return this.property;
};

function destroyProperty(){
    if(!this.destroyed){
        this.destroyed = true;

        this.property
            .removeAllListeners('change')
            .removeAllListeners('update')
            .removeAllListeners('attach');

        this.property.emit('destroy');
        this.property.detach();
        if(this.observable){
            this.observable.destroy && this.observable.destroy(true);
        }
    }
    return this.property;
};

function propertyDestroyed(){
    return this.destroyed;
};

function addPropertyTo(component, key){
    component.setProperty(key, this.property);

    return this.property;
};

function createProperty(currentValue, changes, updater){
    if(typeof changes === 'function'){
        updater = changes;
        changes = null;
    }

    var propertyScope = {
            fastn: this,
            hasChanged: changeChecker(currentValue, changes)
        },
        property = propertyTemplate.bind(propertyScope);

    propertyScope.valueUpdate = function(value){
        property._value = value;
        if(!propertyScope.hasChanged(value)){
            return;
        }
        property.emit('change', property._value);
        property.update();
    };

    var property = propertyScope.property = propertyTemplate.bind(propertyScope);

    property._value = currentValue;
    property._update = updater;

    setPrototypeOf(property, propertyProto);

    property.binding = propertyBinding.bind(propertyScope);
    property.attach = attachProperty.bind(propertyScope);
    property.detach = detachProperty.bind(propertyScope);
    property.update = updateProperty.bind(propertyScope);
    property.updater = propertyUpdater.bind(propertyScope);
    property.destroy = destroyProperty.bind(propertyScope);
    property.destroyed = propertyDestroyed.bind(propertyScope);
    property.addTo = addPropertyTo.bind(propertyScope);

    return property;
};

module.exports = createProperty;