module.exports = function(extra){
    var components = {
        // The _generic component is a catch-all for any component type that
        //  doesnt match any other component constructor, eg: 'div'
        _generic: require('./genericComponent'),

        // The text component is used to render text or bindings passed as children to other components.
        text: require('./textComponent'),

        // The list component is used to render items based on a set of data.
        list: require('./listComponent'),

        // The templater component is used to render one item based on some value.
        templater: require('./templaterComponent')
    };

    if(extra){
        Object.keys(extra).forEach(function(key){
            components[key] = extra[key];
        });
    }

    return components;
}